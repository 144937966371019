.card-C {
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px; /* Rounded corners */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.card-C:hover {
  transform: scale(1.05); /* Zoom effect on hover */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.stat-card {
  position: relative;
  color: white; /* Text color for stat cards */
}

.birthday-card {
  background-color: #fce4ec; /* Light pink background for birthday cards */
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.cardImage{
  aspect-ratio: 3/2;
}

.card-title {
  font-size: 1.25rem; /* Larger font for titles */
}

.card-text {
  font-size: 0.9rem; /* Font size for card text */
  color: #333; /* Dark text color */
}

/* Adding specific colors for stat cards */
.bg-primary {
  background: linear-gradient(135deg, #007bff, #0056b3);
}

.bg-success {
  background: linear-gradient(135deg, #28a745, #218838);
}

.bg-warning {
  background: linear-gradient(135deg, #ffc107, #e0a800);
}

.bg-info {
  background: linear-gradient(135deg, #17a2b8, #138496);
}

.bg-birthday {
  background: linear-gradient(135deg, #26fc1b, #06c103);
}