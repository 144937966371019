.table-active {
    background-color: lawngreen; /* Light green for Active */
}

.table-inactive {
    background-color: #f8d7da; /* Light red for Inactive */
}

.table-suspended {
    background-color: #fff3cd; /* Light yellow for Suspended */
}

.table-complete {
    background-color: #cce5ff; /* Light blue for Complete */
}

.table-transferred {
    background-color: #e2e3e5; /* Light gray for Transferred */
}

.scrollable-table {
    max-height: 50vh;
    overflow-y: auto;
}

