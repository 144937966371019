.one-line-text{
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}


.student-card {
    border: none; /* Remove default border */
    border-radius: 10px; /* Round the corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s; /* Smooth transition for hover effect */
}

.student-card:hover {
    transform: translateY(-5px); /* Lift card on hover */
}

.card-image-container {
    position: relative;
    height: 150px; /* Fixed height for images */
    overflow: hidden; /* Hide overflow */
}

.card-image-container img {
    width: 100%; /* Image takes full width */
    height: 100%; /* Image takes full height */
    object-fit: cover; /* Cover the container without distortion */
    border-radius: 10px 10px 0 0; /* Round top corners of image */
}

.paid {
    background-color: #e0f7e0; /* Light green for paid */
    border-left: 5px solid green; /* Green left border */
}

.unpaid {
    background-color: #f8d7da; /* Light red for unpaid */
    border-left: 5px solid red; /* Red left border */
}

.student-name {
    font-size: 1.2rem; /* Larger font size for name */
    font-weight: bold; /* Bold name */
    text-align: center; /* Centered text */
    margin: 10px 0; /* Margins for spacing */
}

.card-text {
    font-size: 0.9rem; /* Slightly smaller text */
    color: #555; /* Text color */
}