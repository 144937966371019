.sidebar {
    background-color: white;
    padding: 10px;
    border-right: 1px solid #ccc;
    height: 70vh;
    overflow-y: auto;
}

.student-item {
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.student-item:hover {
    background-color: rgba(0, 0, 255, 0.1);
}

.student-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.student-detail {
    display: flex;
    align-items: center;
}

.student-detail-image {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin-right: 20px;
}

.student-info {
    flex: 1;
}

.fee-details {
    border-top: 1px solid #ccc;
    margin-top: 20px;
    padding-top: 10px;
}

.fee-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.fee-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.table {
    margin-top: 20px;
}

.total-fees {
    margin-top: 20px;
    font-weight: bold;
    text-align: right;
}

.sticky-filters {
    position: sticky;
    top: 0px;
    z-index: 1000;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.card-stu:hover{
    transform: scale(1.005);
    transition: transform 1s ease;
}

/* Additional responsive adjustments */
@media (max-width: 768px) {
    .total-fees {
        font-size: 0.9rem;
    }
}


@media (max-width: 768px) {
    .table {
        font-size: 0.9rem;
    }
}